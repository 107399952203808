import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "./contexts/UserContext";
import ChatBot from "./components/ChatBot/ChatBot";
// import logoImage from "./components/ChatBot/assets/Benny-Button-favicon.png";
import { ChatBotEvent } from "./utils/constants";

const ChatBotComponent = () => {
  const baseURL = process.env.REACT_APP_API_DOMAIN;
  const isAnonymous =  process.env.REACT_APP_ALLOW_ANONYMOUS
  // const bbApiToken = process.env.REACT_APP_BB_API_ACCESS_KEY;

  const { userToken, user } = useAuth();
  /* eslint-disable no-unused-vars */
  const [aiUser, setAiUser] = useState(true);
  const [showChatBot, setShowChatBot] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [botSessionId, setBotSessionId] = useState("");
  const [email, setEmail] = useState("");
  const [isAlreadyShow, setIsAlreadyShow] = useState(false);
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    document.addEventListener(
      ChatBotEvent.close,
      (e) => setShowChatBot(false),
      false
    );
  }, []);

  useEffect(() => {
    document.addEventListener(
      ChatBotEvent.visible,
      (e) => {
        setAiUser(true);
        setEmail(e.email);
      },
      false
    );
  }, []);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
    }
  }, [user]);

  // useEffect(() => {
  //   setAiUser(baseURL && baseURL.toString()?.includes("api-dev.") );
  // }, [showChatBot, baseURL, userToken]);
  useEffect(() => {
    setAiUser(
        (!isAnonymous) &&
      userToken &&
        user &&
        baseURL &&
        (baseURL.toString()?.includes("api-dev.") ||
          baseURL.toString()?.includes("api."))
    );
  }, [baseURL, userToken, user, isAnonymous]);

  useEffect(() => {
    let header = userToken
      ? {
          Authorization: `Bearer ${userToken}`,
        }
      : null;

    if (showChatBot && !isAlreadyShow) {
      axios
        .post(
          `${baseURL}v1/bot/dialogflow/start?email=${email}`,
          {},
          {
            headers: header,
          }
        )
        .then((response) => {
          if (response.data.status === 201) {
            let body = JSON.parse(response.data.body);
            setBotSessionId(body.session_id);
          } else {
            setApiError("Session could not be started!!!");
          }
        })
        .catch((error) => {
          if (error.response) {
            setApiError(`Error: ${error.response.data}`);
            console.error(apiError + " : " + error.response.data);
          } else if (error.request) {
            setApiError("Network error occurred.");
            console.error(apiError + " : " + error.request);
          } else {
            setApiError(`Error: ${error.message}`);
            console.error(apiError + " : " + error.message);
          }
        });
      setIsAlreadyShow(true);
    }
  }, [showChatBot, baseURL, userToken, apiError, email, isAlreadyShow]);

  const toggleChatBot = () => {
    setShowChatBot((prevState) => !prevState);
  };

  return (
    <div className="chat-page">
      {!apiError && aiUser === true && (
        <div
          className={`chatbot-icon ${showChatBot ? "active" : ""}`}
          onClick={toggleChatBot}
        >
          {/*<div className="chatbot-icon-div">*/}
          {/*  <img*/}
          {/*    src={logoImage}*/}
          {/*    className="chatbot-icon"*/}
          {/*    alt="ChatBot Icon"*/}
          {/*    width={50}*/}
          {/*    height={50}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      )}

      {apiError ? (
        <div className="error-message">{apiError}</div>
      ) : (
        <ChatBot
          customStyle={{
            display: showChatBot ? "block" : "none",
          }}
          onClose={toggleChatBot}
          session_id={botSessionId}
        />
      )}
    </div>
  );
};

export default ChatBotComponent;
